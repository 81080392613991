import vertical from './assets/preset-thumbnails/default/vertical.png';
import vertical_no_image from './assets/preset-thumbnails/default/vertical_no_image.png';
import vertical_tt_d from './assets/preset-thumbnails/default/vertical_tt_d.png';
import horizontal_fb from './assets/preset-thumbnails/horizontal-full-width/horizontal_fb.png';
import horizontal_fb_no_image from './assets/preset-thumbnails/horizontal-full-width/horizontal_fb_no_image.png';
import horizontal_fb_tt_d from './assets/preset-thumbnails/horizontal-full-width/horizontal_fb_tt_d.png';
import horizontal from './assets/preset-thumbnails/horizontal/horizontal.png';
import horizontal_no_image from './assets/preset-thumbnails/horizontal/horizontal_no_image.png';
import horizontal_tt_d from './assets/preset-thumbnails/horizontal/horizontal_tt_d.png';
import vertical_fb from './assets/preset-thumbnails/vertical-full-width/vertical_fb.png';
import vertical_fb_no_image from './assets/preset-thumbnails/vertical-full-width/vertical_fb_no_image.png';
import vertical_fb_tt_d from './assets/preset-thumbnails/vertical-full-width/vertical_fb_tt_d.png';

export default {
  vertical_no_image,
  vertical_tt_d,
  vertical,
  horizontal_no_image,
  horizontal_tt_d,
  horizontal,
  horizontal_fb_no_image,
  horizontal_fb_tt_d,
  horizontal_fb,
  vertical_fb_no_image,
  vertical_fb_tt_d,
  vertical_fb,
};
