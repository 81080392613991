import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { PLAN_COUNT_LIMIT } from '../constants';
import { ElementRole } from '../constants/elements';

export async function getParentRef(editorSDK: EditorSDK, componentRef: ComponentRef): Promise<ComponentRef> {
  const [parentRef] = await editorSDK.document.components.getAncestors('', { componentRef })!;
  return parentRef;
}

export async function getCurrentPresetId(
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
): Promise<string | undefined> {
  const parentRef = await getParentRef(editorSDK, componentRef);
  const preset = await editorSDK.document.application.appStudioWidgets.getPreset('', { componentRef: parentRef });
  return preset.layout;
}

export async function getRootWidget(editorSDK: EditorSDK, componentRef: ComponentRef): Promise<ComponentRef> {
  const ancestors = await editorSDK.components.getAncestors('', { componentRef });
  const ancestorTypes = await Promise.all(
    ancestors.map((ref) => editorSDK.components.getType('', { componentRef: ref })),
  );
  /*
    There might be more than one AppWidget type component
    in the hierarchy. `getAncestors` returns ancestors in order
    from closest to furthest, so `lastIndexOf` will find the root widget
  */
  const widgetIndex = ancestorTypes.lastIndexOf('platform.components.AppWidget');
  return ancestors[widgetIndex];
}

export async function getPlanWidget(editorSDK: EditorSDK, rootWidgetRef: ComponentRef) {
  const [controllerRef] = await editorSDK.components.getChildren('', { componentRef: rootWidgetRef });
  const [planComponent] = await editorSDK.components.findAllByRole('', { controllerRef, role: ElementRole.PlanWidget });
  return planComponent;
}

export async function assignLatestPlanToWidgetIfNeeded(params: {
  widgetRef: ComponentRef;
  httpClient: IHttpClient;
  editorSDK: EditorSDK;
  currentPlanId?: string;
}): Promise<void> {
  const { widgetRef, httpClient, editorSDK, currentPlanId } = params;
  const { data } = await httpClient.request(listPublicPlans({ limit: PLAN_COUNT_LIMIT }));
  const isCurrentPlanValid = data.plans?.some((plan) => plan.id === currentPlanId);
  if (!isCurrentPlanValid) {
    const [newPlan] = data.plans ?? [];
    if (newPlan) {
      await editorSDK.document.application.appStudioWidgets.props.set('', {
        widgetRef,
        newProps: { planId: newPlan.id },
      });
    }
  }
}
