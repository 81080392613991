import { createPlanClick, pricingPlansInstallationStage } from '@wix/bi-logger-membership/v2';
import { maybeInstallMembersArea, MA_APP_IDS, withMembersArea } from '@wix/members-area-integration-kit';
import {
  CustomActionConfig,
  EditorPlatformApp,
  JsonObject,
  InstallationOriginType,
  ComponentRef,
  PageRef,
} from '@wix/platform-editor-sdk';
import {
  EditorReadyFn,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  FlowPlatformOptions,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';
import { Logger } from '@wix/yoshi-flow-editor/build/esm/exports/bi';
import pricingPlans from '../.application.json';
import planList from './components/PlanList/.component.json';
import { EXPERIMENTS } from './constants';
import { createMemebersAreaInstallLogger } from './services/membersAreaInstallLogger';
import {
  addSinglePlanWidget,
  getBlocksWidgetManifest,
  onComponentGfppClicked,
  onGlobalDesignPresetChanged,
  onWidgetGfppClicked,
} from './single-plan-editor-config';
import { PrivatePricingPlansApi, PublicPricingPlansApi } from './types/editor';
import { toError } from './utils/errors';
import { setPermissionsPage } from './utils/setPermissionsPage';
import { getPlanWidget, getParentRef } from './utils/widget';

const AUTOPILOT_USER_ID = '6316f5bb-5858-4345-92ed-04566c1d7f54';
const ACTION_APP_INSTALLED = 'appInstalled';
const INSTALLATION_TYPE_AUTOPILOT = 'autopilot';
const INSTALLATION_TYPE_REGULAR = 'regular';

let appDefinitionId: string;
let editorSDK: FlowEditorSDK;
let flowAPI: EditorScriptFlowAPI;
let translate: EditorScriptFlowAPI['translations']['t'];
let biLogger: Logger;
let initialAppData: FlowPlatformOptions['initialAppData'];

const isAutopilotUser = async () => (await editorSDK.info.getUserInfo(appDefinitionId)).userId === AUTOPILOT_USER_ID;

const reportPricingPlansInstallationStage = async (stage: string) => {
  const isAutopilot = await isAutopilotUser();
  await biLogger.report(
    pricingPlansInstallationStage({
      appId: appDefinitionId,
      appInstanceId: await editorSDK.info.getAppInstanceId(appDefinitionId),
      biToken: await editorSDK.info.getMetaSiteId(appDefinitionId),
      installationType: isAutopilot ? INSTALLATION_TYPE_AUTOPILOT : INSTALLATION_TYPE_REGULAR,
      stage,
    }),
  );
};

const _editorReady: EditorReadyFn = async (sdk, appDefId, options, _flowAPI) => {
  appDefinitionId = appDefId;
  editorSDK = sdk;
  flowAPI = _flowAPI;
  translate = _flowAPI.translations.t;
  initialAppData = options.initialAppData;
  biLogger = options.essentials.biLoggerFactory().logger();

  // @todo: sarunas - IMHO this should be removed, cause no need each time set custom error page (or is it?)
  await trySetPermissionsPage();

  if (options.firstInstall) {
    // if (flowAPI.experiments.enabled(EXPERIMENTS.USE_BLOCKS_PACKAGE_PICKER_PAGE)) {
    //   const pageRef = await editorSDK.pages.add('', {
    //     title: 'Plans & Pricing',
    //     definition: {
    //       id: '',
    //       type: 'Page',
    //       components: [],
    //       data: {
    //         managingAppDefId: PRICING_PLANS_APP_DEF_ID,
    //       },
    //     },
    //     shouldAddMenuItem: true,
    //     shouldNavigateToPage: true,
    //   });
    //   console.log('firstInstall', { pageRef });
    //   const isInstalled = await editorSDK.application.isApplicationInstalled('', {
    //     appDefinitionId: BLOCKS_APP_DEF_ID,
    //   });
    //   console.log('firstInstall', { isInstalled });
    //   if (!isInstalled) {
    //     const result = await editorSDK.application.add('', {
    //       appDefinitionId: BLOCKS_APP_DEF_ID,
    //       managingAppDefId: PRICING_PLANS_APP_DEF_ID,
    //       shouldNavigate: false,
    //       originInfo: {
    //         doNotAddWidget: true,
    //       },
    //     });
    //     console.log('firstInstall', { installationResult: result });
    //   }
    //   const api = await editorSDK.application.getPublicAPI('', { appDefinitionId: BLOCKS_APP_DEF_ID });
    //   console.log('firstInstall', { api });
    //   if ((api as any).addPackagePickerWidget) {
    //     const result = await (api as any).addPackagePickerWidget(pageRef, 'closed');
    //     console.log('firstInstall', { addPackagePickerWidgetResult: result });
    //   }
    // }

    if (
      flowAPI.experiments.enabled(EXPERIMENTS.FORCE_MEMBERS_AREA_INSTALL) ||
      (options.origin?.info?.type !== InstallationOriginType.SILENT_INSTALL_SITE_CREATION &&
        options.origin?.info?.type !== InstallationOriginType.SILENT_INSTALL)
    ) {
      await maybeInstallMembersArea({ biData: options.biData });
    }
  }
};

const _handleAction: EditorPlatformApp['handleAction'] = async ({ type, payload }) => {
  if (
    type === ACTION_APP_INSTALLED &&
    (payload as Record<string, string>).appDefinitionId === pricingPlans.appDefinitionId
  ) {
    await reportPricingPlansInstallationStage(ACTION_APP_INSTALLED);
    await trySetPermissionsPage();
  }
};

const trySetPermissionsPage = async () => {
  try {
    await setPermissionsPage(editorSDK, appDefinitionId);
  } catch (e) {
    flowAPI.reportError(toError(e));
  }
};

const platformApp = withMembersArea(
  {
    editorReady: _editorReady,
    handleAction: _handleAction,
  },
  {
    installAutomatically: false,
    disableADI: false,
    membersAreaApps: [MA_APP_IDS.MY_SUBSCRIPTIONS, MA_APP_IDS.MY_WALLET],
    onEvent: createMemebersAreaInstallLogger({
      fedopsLogger: () => flowAPI.fedops,
      isAutopilotUser,
      reportPricingPlansInstallationStage,
    }),
  },
);

export const editorReady = platformApp.editorReady;
export const handleAction = platformApp.handleAction;

export const getAppManifest: GetAppManifestFn = async (...params) => {
  const buildCustomActions = () => {
    const result: CustomActionConfig[] = [];
    result.push({
      title: translate('manage-apps-panel.manage-purchases'),
      actionId: 'openManagePurchases',
      icon: 'appManager_settingsAction',
      type: 'dashboard',
    });
    result.push({
      title: translate('manage-apps-panel.customize-plans-page'),
      icon: 'appManager_pagesAction',
      actionId: 'openPricingPlansSettings',
      type: 'editorActions',
    });
    result.push({
      title: translate('manage-apps-panel.add-pricing-plans-elements'),
      icon: 'appManager_addElementsAction',
      actionId: 'addPricingPlansElements',
      type: 'editorActions',
    });
    result.push({
      title: translate('manage-apps-panel.manage-email-automations'),
      icon: 'email_icon',
      actionId: 'openMyAutomations',
      type: 'dashboard',
    });
    return result;
  };

  const blocksManifest = await getBlocksWidgetManifest(...params);
  return {
    routers: undefined, // not in this article, not needed in a new manifest
    controllersStageData: {
      [planList.id]: {
        default: {
          displayName: translate('manifest.plan-list-widget.display-name'),
        },
      },
      ...blocksManifest.controllersStageData,
    },
    appDescriptor: {
      mainActions: [
        {
          title: translate('manage-apps-panel.manage-plans'),
          actionId: 'openManagePlans',
          icon: 'appManager_settingsAction',
        },
        {
          title: translate('manage-apps-panel.create-new-plan'),
          actionId: 'createNewPlan',
          icon: 'appManager_addElementsAction',
        },
      ],
      customActions: buildCustomActions(),
      defaultActions: {
        upgrade: {
          upgradeType: 'SITE_UPGRADE',
          upgradeText: translate('manage-apps-panel.upgrade-text'),
          upgradeLinkText: translate('manage-apps-panel.upgrade-link-text'),
        },
        learnMoreKB: 'b1d66914-9073-43d1-af55-f923aac621b0',
      },
    },
    exports: {
      ...blocksManifest.exports,
    },
  };
};

export const onEvent: EditorPlatformApp['onEvent'] = async ({ eventType, eventPayload }) => {
  if (eventType === 'appActionClicked') {
    const { actionId } = eventPayload;
    if (actionId === 'openManagePlans') {
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/pricing-plans?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'createNewPlan') {
      await biLogger.report(
        createPlanClick({
          appInstanceId: await editorSDK.info.getAppInstanceId(appDefinitionId),
          biToken: await editorSDK.info.getMetaSiteId(appDefinitionId),
          formOrigin: 'manage_app_panel',
          referralInfo: 'manage_app_panel',
          origin: 'editor',
        }),
      );
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/pricing-plans/new?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'openManagePurchases') {
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/subscriptions/?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'openPricingPlansSettings') {
      await privateApi.openSettings();
    } else if (actionId === 'addPricingPlansElements') {
      await privateApi.openAddons();
    } else if (actionId === 'openMyAutomations') {
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/triggers/?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    }
  } else if (eventType === 'widgetGfppClicked') {
    onWidgetGfppClicked({ eventPayload, editorSDK, flowAPI });
  } else if (eventType === 'globalDesignPresetChanged') {
    onGlobalDesignPresetChanged({ eventPayload, editorSDK, flowAPI });
  } else if (eventType === 'componentGfppClicked') {
    onComponentGfppClicked({ eventPayload, editorSDK, flowAPI });
  }
};

const privateApi: PrivatePricingPlansApi = {
  async openAddons() {
    const { openAddons } = await import('./utils/settings');
    await openAddons(editorSDK, translate, initialAppData.editorScriptUrl);
  },
  async openSettings() {
    const { openPricingPlansSettings } = await import('./utils/settings');
    await openPricingPlansSettings(editorSDK, biLogger);
  },
  async addPlanListWidget(pageId: string) {
    const { addPlanListWidgetToStage } = await import('./utils/components');
    await addPlanListWidgetToStage(editorSDK, pageId);
  },
  async addSinglePlanWidget(pageId: string) {
    const { addSinglePlanWidgetToStage } = await import('./utils/components');
    await addSinglePlanWidgetToStage(editorSDK, pageId);
  },
  async addSinglePlanBlocksWidget(pageId: string, planId: string) {
    const pageRef = { id: pageId, type: 'DESKTOP' } as PageRef;
    await editorSDK.pages.navigateTo('', { pageRef });
    const componentRef = await addSinglePlanWidget(editorSDK);

    if (planId) {
      const [widgetRef] = await editorSDK.components.getChildren('', { componentRef });
      await editorSDK.application.appStudioWidgets.props.set('', { widgetRef, newProps: { planId } });
    }
  },
};

const publicApi: PublicPricingPlansApi = {
  async setDemoData(plans) {
    const components = await editorSDK.tpa.app.getAllCompsByApplicationId(
      appDefinitionId,
      initialAppData.applicationId,
    );

    if (components.length) {
      await editorSDK.tpa.data.set(appDefinitionId, {
        compRef: { id: components[0].id, type: 'DESKTOP' },
        key: 'demoData',
        scope: 'APP',
        value: ({ plans } as unknown) as JsonObject,
      });
    } else {
      throw new Error('No Pricing Plans components found on site');
    }
  },
};

export const exports: EditorPlatformApp['exports'] = () => {
  return {
    private: privateApi,
    public: publicApi,
    editor: {
      getSelectedWidgetDesignPresetId: async ({ widgetRef: rootWidgetRef }) => {
        const planWidget = await getPlanWidget(editorSDK, rootWidgetRef as ComponentRef);
        const planWidgetParentRef = await getParentRef(editorSDK, planWidget);
        return (
          await editorSDK.application.appStudioWidgets.getPreset('', {
            componentRef: planWidgetParentRef,
          })
        ).style;
      },
    },
  };
};
